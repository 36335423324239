<template>
  <div id="scrolling-techniques-6" class="home full-height">
  <!-- Banner -->
    <section class="full-height" id="banner">
      <div>
        <v-carousel hide-delimiter-background :show-arrows="false">
          <v-carousel-item
            v-for="(item,i) in slides"
            :key="i"
            src="../../assets/images/test12.jpeg"
          >
            <div class="pa-md-14 pt-14 px-3 carousel-text-container">
              <div class="carousel-first-text-container pa-8 pa-md-12">
                <div>
                  <span class="text-md-h3 text-h5 font-weight-medium ">
                    DIAGNOSTICO VIRTUAL Y PREPARACION DENTAL GUIADA PARA LA REHABILITACION MINIMAMENTE INVASIVA
                  </span>
                </div>
                <div class="mt-5">
                  <v-btn color="secondary">
                    VER MAS
                  </v-btn>
                </div>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div>
        <v-img width="100%" height="300px" src="../../assets/images/test2.jpg">
          <div class="welcome-text-container px-6 pt-5 px-md-15 pt-md-12">
            <div class="mb-md-3 mb-1">
              <span class="secondary--text text-h5 text-md-h4 font-weight-bold">
                BIENVENIDOS A <span class="secondary white--text px-2">TOD3D</span>
              </span>
            </div>
            <div>
              <span class="white--text text-caption text-md-subtitle-1">
                SOMOS UN GRUPO DE PROFESIONALES CON UN CONCEPTO DE FAMILIA AVOCADO AL DESARROLLO DE PROTOCOLOS PARA LA EVOLUCION DE LA ODONTOLOGIA DIGITAL.
              </span>
            </div>
            <div class="mt-3 mt-md-5">
              <v-btn color="secondary">
                VER MAS
              </v-btn>
            </div>
          </div>
        </v-img>
      </div>
      <div class="pt-5 d-flex flex-column align-center">
        <div class="width-80 mt-5 mb-8">
          <span class="secondary--text text-h5 font-weight-bold">
            NOVEDADES
          </span>
        </div>
        <div class="width-80 d-flex flex-wrap justify-space-between">
          <div
            v-for="career in careers"
            :key="`career-${career.name}`"
            class="single-new-container pointer mb-7"
          >
            <CourseBox
              :content="career.preview"
              :title="career.name"
              :description="career.shortDescription"
              @courseSelection="onCourseBoxClick(career.name)"
            />
          </div>
        </div>
        <div class="my-9">
          <v-btn color="secondary">
            VER MAS
          </v-btn>
        </div>
        <div class="sponsors-container d-flex justify-space-around align-center py-4">
          <v-img max-width="100px" src="../../assets/images/sponsor1.jpg"/>
          <v-img max-width="100px" src="../../assets/images/sponsor2.jpg"/>
          <v-img max-width="100px" src="../../assets/images/sponsor3.jpg"/>
          <v-img max-width="100px" src="../../assets/images/sponsor4.jpg"/>
          <v-img max-width="100px" src="../../assets/images/sponsor5.jpg"/>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="mt-6 px-10">
        <h2>T.O.D.3D</h2>
        <p>Todo Odontologia Digital 3D</p>
      </div> -->
    </section>

  <!-- Main -->
    <!-- <section id="main" class="container">
      <section class="box special features">
        <div class="features-row">
          <section @click="navigateTo(careerCategory.route)" v-for="careerCategory in careerCategories" :key="`course-${careerCategory.name}`" >
            <span :class="`icon solid major fa-bolt accent${Math.floor(Math.random() * Math.floor(6))}`"></span>
            <h3>{{careerCategory.name}}</h3>
            <p>Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros.</p>
            <br/>
            <br/>
          </section>
        </div>
      </section>
    </section> -->
  </div>
</template>

<script>
import CourseBox from '@/components/CourseBox.vue'
import { db } from '@/firebaseSDK'

export default {
  name: 'Home',

  components: {
    CourseBox
  },

  data: () => ({
    careerCategories: [],
    careers: [],
    slides: [
      {
        name: 'First',
        image: '../../assets/images/test1.jpeg',
        text:'',
        button: {
          text: 'Ver mas',
          link: ''
        }
      },
      {
        name: 'Second',
        image: '../../assets/images/test1.jpeg',
        text:'',
        button: {
          text: 'Ver mas',
          link: ''
        }
      },
      {
        name: 'Third',
        image: '../../assets/images/test1.jpeg',
        text:'',
        button: {
          text: 'Ver mas',
          link: ''
        }
      }
    ],
    items: [
      {
        src: './../../assets/images/banner1.png',
      },
      {
        src: './../../assets/images/banner2.png',
      },
      {
        src: './../../assets/images/banner3.png',
      }
    ],
    slidesCount: 3,
    currentSlideIndex: 0
  }),

  methods: {
    navigateTo(route) {
      this.$router.push(route)
    },

    onCourseBoxClick (id) {
      this.$router.push(`/courses/${id}`)
    }
  },

  async mounted () {
    try {
      let careers = await db.collection("careers").get()
      console.log(careers, '<')
      let fetchedCareers = careers.docs.map((doc) => doc.data())
      this.careers = fetchedCareers
    } catch (err) {
      return err
    }
    // try {
    //   let careerCategories = await db.collection("careers-categories").get()
    //   this.careerCategories = careerCategories.docs.map((doc) => doc.data())
    // } catch (err) {
    //   return err
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.sponsors-container {
  width: 100%;
  background-color: #d9dade;
}

.footer-container {
  width: 100%;
  background-color: rgba(79, 81, 80, 1);
}

.carousel-text-container {
  height: 100%;
}

.carousel-first-text-container {
  @media (min-width: 960px) {
    width: 60%;
  }
}

.welcome-text-container {
  position: absolute;
  width: 60%;
  @media (min-width: 960px) {
    width: 50%;
  }
}

.full-height {
  height: 100%;
}

.single-new-container {
  width: 100%;
  @media (min-width: 960px) {
    max-width: 250px;
  }
}

.test-container {
  height: 100%;
  width: 100vw;
}

.width-80 {
  width: 90%;
}

.test-image-holder {
  width: 100%
}

.image-footer {
  filter: invert(1);
}

.second-slide-text {
  grid-area: 1 / 1 / span 12 / span 24;

  @media (min-width: 960px) {
    grid-area: 9 / 4 / span 4 / span 10;
  }
}

.second-slide-image {
  grid-area: 1 / 1 / span 12 / span 24;

  @media (min-width: 960px) {
    grid-area: 12 / 11 / span 18 / span 10;
  }
}

.test-text-holder {
  grid-area: 14 / 2 / span 8 / span 21;

  @media (min-width: 960px) {
    grid-area: 9 / 14 / span 18 / span 10;
  }
}

.card-container{
  width: 400px
}

.pointer:hover {
  color: var(--v-secondary-base)
}

.content-holder {
  margin: 0px !important;
  width: 100%;
  height: calc(100VH);
  display: flex;
  align-items: center;
}

.controllers {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 40px;
  width: 100%
}

.gradient-live {
  background-image: linear-gradient(to right, #c231b4 , #f33056);
}

.relative-container {
  position: relative;
}

.card-behind {
  position: absolute;
  // left: 130px;
  top: -40vh;
  right: -6vw;
  width: 330px;
  z-index: 0;
}

.card-front {
  position: absolute;
  top: -25vh;
  right: 4vw;
  width: 340px;
}

.logo {
  height: 400px;
}

.carousel-container {
  height: 100%;
}

.family-text {
  margin-left: 97px
}

.button-family-text {
  margin-left: 288px
}
</style>
