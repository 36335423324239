<template>
  <v-slide-x-transition mode="out-in">
    <v-card
      class="mx-auto card-container d-flex d-md-block align-start"
      max-width="100%"
      v-if="realContentStr"
      @click="$emit('courseSelection', title)"
    >
      <v-img
        :src="realContentStr"
        class="course-preview"
      ></v-img>

      <!-- <video v-if="realContentStr" controls>
        <source :src="realContentStr" type="video/mp4">
      </video> -->

      <v-card-title class="pt-2 px-3 pb-0">
        <span class="text-subtitle-1 text-md-h6 font-weight-medium title">
          {{title}}
        </span>
        <v-card-text class="course-description pa-0">
          <div class="text-caption text-md-subtitle-2">{{description}}</div>
        </v-card-text>
      </v-card-title>

      <v-card-actions class="d-none d-md-flex">
        <v-btn
          color="red lighten-1"
          text
        >
          Ver mas
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-slide-x-transition>
</template>

<script>
import { storage } from '@/firebaseSDK'

export default {
  name: 'CourseBox',

  props: {
    content: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    }
  },

  data: () => {
    return {
      show: false,
      realContentStr: '',
      messages: [
        {
          from: 'Dalla Costa Lucas',
          message: `Introduccion`,
          time: '10:00 pm',
          color: 'grey lighten-3',
        },
        {
          from: 'Loys Adrian',
          message: 'Diseño',
          time: '10:10 pm',
          color: 'green lighten-3',
        },
        {
          from: 'Dalla Costa Lucas',
          message: 'Sorteo',
          time: '10:40 pm',
          color: 'yellow lighten-3',
        },
      ],
    }
  },

  methods: {
    onInstagramClick () {
      window.location.href = 'https://www.instagram.com/p/CIyUm8ElC9-/'
    }
  },

  async mounted () {
    if (this.content) {
      this.realContentStr = await storage.refFromURL(this.content).getDownloadURL()
    }
  }
}
</script>

<style lang="scss" scoped>

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-preview {
  height: 130px;
  max-width: 130px;
  border-radius: 5px;

  @media (min-width: 960px) {
    max-width: initial;
    border-radius: 0px;
    width: 100%;
    // height: 240px;
  }
}

.course-description {
  max-height: 78px;
  overflow: hidden;
  word-break: break-word;

  @media (min-width: 960px) {
    white-space: nowrap;
    word-break: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    max-height: 22px;
  }
}

.card-container {
  // box-shadow: initial !important;

  // @media (min-width: 960px) {
  //   box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  // }
}

</style>